	.pagination > li > a:hover,
	.pagination > li > span:hover,
	.pagination > li > a:focus,
	.pagination > li > span:focus,
	.list-group-item.active,
	.list-group-item.active:hover,
	.list-group-item.active:focus,
	.panel-primary > .panel-heading,
	.breadcrumb,
	blockquote,
	blockquote.pull-right,
	.nav .open > a,
	.nav .open > a:hover,
	.nav .open > a:focus,
	.form-control:focus,
	.nav .open > a,
	.nav .open > a:hover,
	a.thumbnail:hover,
	.nav-tabs.nav-stacked > li > a,
	.owl-carousel .item a div span,
	a.thumbnail:focus,
	.post-wrapper-top h2,
	.nav .open > a:focus,
	#volume,
	.title:before,
	.post-meta a:hover,
	 .title:after{
		border-color:#3498db
	}

	.panel-primary > .panel-heading + .panel-collapse .panel-body,
	.navbar-default .navbar-nav > .active > a,
	.navbar-inverse .navbar-nav > .active > a,
	.navbar-default .navbar-nav > .active > a:hover,
	.navbar-inverse .navbar-nav > .active > a:hover,
	.navbar-default .navbar-nav > .active > a:focus,
	.navbar-inverse .navbar-nav > .active > a:focus,
	.nav .caret {
	  border-bottom-color: #3498db;
	}

	.panel-primary > .panel-heading + .panel-collapse .panel-body,
	.nav .caret,
	.blue > li:hover > a, .blue > li.active > a,
	.top-bar-section .dropdown,
	 .top-bar-section .has-dropdown > a:after {
		border-top-color:#3498db
	}
	
	.dm-icon-effect-1 .dm-icon:after {
		box-shadow: 0 0 0 5px #3498db;
	}
	.site-title span,
	.text-primary,
	.panel-primary > .panel-heading,
	.navbar-inverse .dropdown-menu > li > a:hover,
	.navbar-inverse .dropdown-menu > li > a:focus,
	.nav > li > a:hover,
	.nav > li > a:focus,
	.accordion-heading .accordion-toggle > em,
	.message span,
	.services_lists_boxes_icon:after,
	#cp-nav a:hover, #cp-nav .hover > a, #cp-nav .current > a,
	#cp-nav .hover > a a:after,
	#cp-nav .current a:after,
	a.list-group-item.active > .badge,
	.nav-pills > .active > a > .badge,
	a,
	.rating i,
	.btn-link,
	.dropdown-menu > li > a:hover,
	.dropdown-menu > li > a:focus,
	.dropdown-menu > .active > a,
	.dropdown-menu > .active > a:hover,
	.dropdown-menu > .active > a:focus,
	.nav > li > a:hover,
	.nav > li > a:focus,
	.check li:before,
	.highlight,
	.required,
	.pricing li:before,
	.theme_details h3,
	.theme_overviews .icon-container i,
	.jetmenu li > .megamenu i,
	.top-bar-section ul li:hover > a,
	.top-bar-section ul li.active > a,
	.recent_posts li a.readmore {
		color:#3498db;
	}

	.pagination > .active > a,
	.pagination > .active > span,
	.pagination > .active > a:hover,
	.pagination > .active > span:hover,
	.pagination > .active > a:focus,
	.pagination > .active > span:focus,
	.pagination > li > a:hover,
	.pagination > li > span:hover,
	.pagination > li > a:focus,
	.pagination > li > span:focus,
	.nav-tabs.nav-stacked > li > a:hover,
	.nav-tabs.nav-stacked > li > a:focus,
	.nav-tabs.nav-stacked > .active > a,
	.nav-tabs.nav-stacked > .active > a:hover,
	.nav-tabs.nav-stacked > .active > a:focus,
	.nav-list > .active > a,
	.nav-list > .active > a:hover,
	.nav-list > .active > a:focus,
	.navbar-default .navbar-nav > li > a:hover,
	.navbar-inverse .navbar-nav > li > a:hover,
	.navbar-default .navbar-nav > li > a:focus,
	.navbar-inverse .navbar-nav > li > a:focus,
	.nav-pills > li.active > a,
	.nav-pills > li.active > a:hover,
	.nav-pills > li.active > a:focus,
	.pager li > a:hover,
	.pager li > a:focus,
	.label-primary,
	.list-group-item.active,
	.list-group-item.active:hover,
	.list-group-item.active:focus,
	.panel-success > .panel-heading,
	.btn-primary,
	.tagcloud a,
	.btn-primary.disabled,
	.btn-primary[disabled],
	fieldset[disabled] .btn-primary,
	.btn-primary.disabled:hover,
	.btn-primary[disabled]:hover,
	fieldset[disabled] .btn-primary:hover,
	.btn-primary.disabled:focus,
	.btn-primary[disabled]:focus,
	fieldset[disabled] .btn-primary:focus,
	.btn-primary.disabled:active,
	.btn-primary[disabled]:active,
	fieldset[disabled] .btn-primary:active,
	.btn-primary.disabled.active,
	.btn-primary[disabled].active,
	fieldset[disabled] .btn-primary.active,
	.topbar,
	.progress-bar-danger,
	.servicetitle hr,
	.icn-container,
	.no-touch .dm-icon-effect-1 .dm-icon.active,
	.no-touch .dm-icon-effect-1 .dm-icon:hover,
	.naver .naver-handle:before,
	button, .button,
	.owl-theme .owl-controls .owl-page.active span,
	.owl-theme .owl-controls.clickable .owl-page:hover span,
	.title:before,
	.owl-carousel .item i,
	#volume,
	mark,
	#bbpress-forums li.bbp-body ul.forum li.bbp-forum-topic-count,
	#bbpress-forums li.bbp-body ul.forum li.bbp-forum-reply-count,
	.dmtop {
		background-color:#3498db;
	}
	
	.services_lists_boxes_icon_none i:hover,
	.services_lists_boxes_icon i:hover {
		background:#3498db !important;
	}
	.transparent-wrap {
		background-color: rgba(42, 205, 201, 0.6);
	}